import React from 'react'
import { Link } from 'react-router-dom'
import Bredcrumb from '../Bredcrumb/Main'
import BGImg from "../../assets/images/bread_crumb_bg.png"
import BGImg1 from "../../assets/images/bread_crumb_bg_one.png"
import BGImg2 from "../../assets/images/bread_crumb_bg_two.png"
import Leave from '../Mencontact/Leave'
import Map from '../Mencontact/Map'

const Main = ({ brdcum }) => {
    return (

        <>
                <Bredcrumb no={1} title="Контакти" paragraph="Наша команда завжди готова вам допомгти 24/7" tag="Контакти" bgimg={BGImg} />

            <Leave />
            <Map />

        </>
    )
}

export default Main
