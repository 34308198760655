import React, { useEffect } from 'react'
import AOS from "aos";
import Trusted from '../HomeMain/Trusted/Main'
import Features from '../HomeMain/Features/Main'
import AboutApp from '../HomeMain/AboutApp/Main'
import DesignBusiness from '../HomeMain/DesignBusiness/Main'
import DesignCoach from '../HomeMain/DesignCoach/Main'
import DesignClient from '../HomeMain/DesignClient/Main'
import Work from '../HomeMain/Work/Main'
import Testimonial from '../HomeMain/Testimonial/Main'
import Pricing from '../HomeMain/Pricing/Main'
import Faq from '../HomeMain/Faq/Main'
import Interface from '../HomeMain/Interface/Main'
import Download from '../HomeMain/Download/Main'
import Story from '../HomeMain/Story/Main'
import Sliderone from '../Menhome/Sliderone';

const Main = ({ setfooter, setnavbar, setbrdcum }) => {

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  useEffect(() => {
    setfooter({ f1: true })
    setnavbar({ n2: true })
    setbrdcum({ b1: true })
    localStorage.setItem("navbar", "home")

  }, [])

  return (
    <>
      <Sliderone />
      <Trusted />
      <Features />
      {/*<AboutApp />*/}
      <DesignBusiness />
      <DesignCoach />
      <DesignClient />
      <Work />
      <Testimonial />
      <Pricing />
      <Faq />
      <Interface />
      <Download />
      {/*<Story />*/}
    </>
  )
}

export default Main
