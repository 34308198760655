import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import Messageicon from "../../assets/images/contact_message_icon.png"
import Mailicon from "../../assets/images/mail_icon.png"
import Callicon from "../../assets/images/call_icon.png"
import Locationicon from "../../assets/images/location_icon.png"
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Leave() {

    const [ name, setName ] = useState('')
    const [ phone, setPhone ] = useState('')
    const [ message, setMessage ] = useState('')

  const handleMessage = (event) => {
      toast("Повідомлення відправлено. Дякуємо!")

      axios.post('https://api.arena.promo/api/support/contact', {
          name: name,
          phone: phone,
          message: message
      })

      event.preventDefault()
      //event.target.reset()
  }

    return (
        <>
        <ToastContainer
        position="bottom-left"
        autoClose={15000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
         />
            <section className="contact_page_section" id="contact">
                <div className="container">
                    <div className="contact_inner">
                        <div className="contact_form">
                            <div className="section_title">
                                <h2>Залиште <span>повідомлення</span></h2>
                                <p>Ми відповімо на нього в максимально короткий термін</p>
                            </div>
                            <form onSubmit={handleMessage}>
                                <div className="form-group">
                                    <input type="text" placeholder="Імʼя" className="form-control" onChange={e => setName(e.target.value)} />
                                </div>
                                <div className="form-group">
                                    <input type="text" placeholder="Телефон" className="form-control" onChange={e => setPhone(e.target.value)} />
                                </div>
                                <div className="form-group">
                                    <textarea className="form-control" placeholder="Повідомлення" onChange={e => setMessage(e.target.value)}></textarea>
                                </div>
                                <div className="form-group mb-0">
                                    <button type="submit" className="btn puprple_btn">Надіслати</button>
                                </div>
                            </form>
                        </div>
                        <div className="contact_info">
                            <div className="icon"><img src={Messageicon} alt="image" /></div>
                            <div className="section_title">
                                <h2>Залишились <span>питання?</span></h2>
                            </div>
                            <ul className="contact_info_list">
                                <li>
                                    <div className="img">
                                        <img src={Mailicon} alt="image" />
                                    </div>
                                    <div className="text">
                                        <span>Email</span>
                                        <a href="mailto:arena.promo.ua@gmail.com">arena.promo.ua@gmail.com</a>
                                    </div>
                                </li>
                                <li>
                                    <div className="img">
                                        <img src={Callicon} alt="image" />
                                    </div>
                                    <div className="text">
                                        <span>Зателефонуйте</span>
                                        <a href="tel:+380 93 609 67 84">+380 93 609 67 84</a>
                                    </div>
                                </li>
                                <li>
                                    <div className="img">
                                        <img src={Locationicon} alt="image" />
                                    </div>
                                    <div className="text">
                                        <span>Адреса</span>
                                        <p>м. Дніпро</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Leave
