import React from 'react'
import img1 from '../../../assets/images/secure_data.png'
import img2 from '../../../assets/images/functional.png'
import img3 from '../../../assets/images/live-chat.png'
import img4 from '../../../assets/images/support.png'
import img5 from '../../../assets/images/features_frame.png'
import Pollimg from "../../../assets/images/feature_icon_poll.png"
import Moneyimg from "../../../assets/images/feature_icon_money.png"
import Abtimg from "../../../assets/images/abt_functional.png"
import Communication from "../../../assets/images/communication.png"
import Statstimg from "../../../assets/images/feature_icon_stats.png"
import Frameimg from "../../../assets/images/features_frame.png"

const Main = ({video}) => {
  return (
    <>
        <section className="row_am features_section video-features" id="features">
            <div className="container">
                <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                <h2><span>Можливості</span> завдяки яким ми є найкращими!</h2>
                <p>У сучасному світі, де конкуренція зростає щодня, важливо мати партнера, який не тільки відповідає стандартам, а й встановлює їх. Ми пишаємося тим, що пропонуємо клієнтам унікальні можливості, які роблять нас лідерами у своїй галузі. Наші ключові переваги – це поєднання інновацій, досвіду та персоналізованого підходу до кожного клієнта.</p>

                </div>
                <div className="feature_detail">
                <div className="left_data feature_box">
                    <div className="data_block" data-aos="fade-right" data-aos-duration="1500">
                    <div className="icon">
                        <img src={Moneyimg} alt="image" />
                    </div>
                    <div className="text">
                        <h4>Всі види оплат для вашої зручності</h4>
                        <p>Оплата банківською карткою, готівка, банківський переказ. Економте час на здійсненні платежу, обираючи зручний і швидкий варіант.</p>
                    </div>
                    </div>
                    <div className="data_block" data-aos="fade-right" data-aos-duration="1500">
                    <div className="icon">
                        <img src={Pollimg} alt="image" />
                    </div>
                    <div className="text">
                        <h4>Опитування серед клієнтів</h4>
                        <p>Аналіз відповідей допомагає зрозуміти, які саме послуги або методи тренувань клієнти вважають найбільш ефективними. Опитування допомагають вам дізнатися, які аспекти тренувань чи послуг подобаються клієнтам, а що варто вдосконалити. </p>
                    </div>
                    </div>
                </div>
                <div className="right_data feature_box">
                    <div className="data_block" data-aos="fade-left" data-aos-duration="1500">
                    <div className="icon">
                        <img src={Communication} alt="image"/>
                    </div>
                    <div className="text">
                        <h4>Спілкування з тренерами та адміністраторами</h4>
                        <p>Можливість клієнтів звернутися до адміністратора чи тренера в будь-який час, швидкі відповіді на запитання, персоналізовані консультації.</p>
                    </div>
                    </div>
                    <div className="data_block" data-aos="fade-left" data-aos-duration="1500">
                    <div className="icon">
                        <img src={Statstimg} alt="image" />
                    </div>
                    <div className="text">
                        <h4>Фінансове планування та розрахунок зарплатні</h4>
                        <p>Фінансове планування та правильний розрахунок зарплатні є ключовими елементами успішного управління будь-якою компанією. Ми пропонуємо інтуїтивно зрозумілі звіти, що дозволяють легко відстежувати витрати та доходи. </p>
                    </div>
                    </div>
                </div>
                <div className="feature_img" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                    <img src={Frameimg} alt="image" />
                </div>
                </div>
            </div>
        </section>
        {/*
        <section className="row_am features_section" id="features">
            <div className="container">
                <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                <h2><span>Features</span> that makes app different!</h2>
                <p>Lorem Ipsum is simply dummy text of the printing and typese tting <br/> indus orem Ipsum has beenthe
                    standard dummy.</p>
                </div>
                <div className="feature_detail">
                <div className="left_data feature_box">
                    <div className="data_block" data-aos="fade-right" data-aos-duration="1500">
                    <div className="icon">
                        <img src={img1} alt="image" />
                    </div>
                    <div className="text">
                        <h4>Secure data</h4>
                        <p>Lorem Ipsum is simply dummy text of the printing and type setting indus ideas.</p>
                    </div>
                    </div>
                    <div className="data_block" data-aos="fade-right" data-aos-duration="1500">
                    <div className="icon">
                        <img src={img2} alt="image" />
                    </div>
                    <div className="text">
                        <h4>Fully functional</h4>
                        <p>Simply dummy text of the printing and typesetting indus lorem Ipsum is dummy.</p>
                    </div>
                    </div>
                </div>
                <div className="right_data feature_box">
                    <div className="data_block" data-aos="fade-left" data-aos-duration="1500">
                    <div className="icon">
                        <img src={img3} alt="image"/>
                    </div>
                    <div className="text">
                        <h4>Live chat</h4>
                        <p>Lorem Ipsum is simply dummy text of the printing and type setting indus ideas.</p>
                    </div>
                    </div>
                    <div className="data_block" data-aos="fade-left" data-aos-duration="1500">
                    <div className="icon">
                        <img src={img4} alt="image" />
                    </div>
                    <div className="text">
                        <h4>24-7 Support</h4>
                        <p>Simply dummy text of the printing and typesetting indus lorem Ipsum is dummy.</p>
                    </div>
                    </div>
                </div>
                <div className="feature_img" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                    <img src={img5} alt="image" />
                </div>
                </div>
            </div>
        </section>*/}
    </>
  )
}

export default Main
