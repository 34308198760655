import { HashLink as Link } from 'react-router-hash-link';
import React from 'react'
import OwlCarousel from 'react-owl-carousel'
import anim from '../../assets/images/anim_line.png'
import blueapp from '../../assets/images/appstore_blue.png'
import whiteapp from '../../assets/images/appstore_white.png'
import blue from '../../assets/images/googleplay_blue.png'
import white from '../../assets/images/googleplay_white.png'
import used1 from '../../assets/images/used01.png'
import used2 from '../../assets/images/used02.png'
import used3 from '../../assets/images/used03.png'
import used4 from '../../assets/images/used04.png'
import msg from '../../assets/images/message_icon.png'
import shield from '../../assets/images/shield_icon.png'
import screen from '../../assets/images/screen.png'
import screen1 from '../../assets/images/screen1.png'
import screen2 from '../../assets/images/screen2.png'
import frame from '../../assets/images/mobile_frame_svg.svg'

function Sliderone() {

    const frmae_slider = {
        loop: true,
        margin: 10,
        nav: false,
        autoplay: true,
        smartSpeed: 1500,
        dots: true,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 1
            }
        }
    }

    return (
        <>
            <section className="banner_section home-banner">
                <div className="container">
                    <div className="anim_line">
                        <span><img src={anim} alt="anim_line" /></span>
                        <span><img src={anim} alt="anim_line" /></span>
                        <span><img src={anim} alt="anim_line" /></span>
                        <span><img src={anim} alt="anim_line" /></span>
                        <span><img src={anim} alt="anim_line" /></span>
                        <span><img src={anim} alt="anim_line" /></span>
                        <span><img src={anim} alt="anim_line" /></span>
                        <span><img src={anim} alt="anim_line" /></span>
                        <span><img src={anim} alt="anim_line" /></span>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-12" data-aos="fade-right" data-aos-duration="1500">
                            <div className="banner_text">
                                <h1><span>Революція</span> у світі спорту</h1>
                                <p>Перша і єдина в Україні система для спортивних студій в Telegram</p>
                                <p>Шукаєте зручний спосіб керувати записами на заняття, відслідковувати розклад та комунікувати з клієнтами?
                                Наша інноваційна платформа для спортивних студій інтегрується з Telegram,
                                дозволяючи швидко і легко організовувати ваші тренування та управляти студією.</p>
                                <div className="section_title" style={{textAlign: 'left'}}>
                                <h2><span>Персональний</span> телеграм бот з вашим лого та назвою!</h2>
                            </div>
                            </div>

                            <div className="used_app">
                                <ul>
                                    <li><img src={used1} alt="image" /></li>
                                    <li><img src={used2} alt="image" /></li>
                                    <li><img src={used3} alt="image" /></li>
                                    <li><img src={used4} alt="image" /></li>
                                </ul>
                                <p>Найкращі <br /> студії вже є нашими клієнтами</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12" data-aos="fade-in" data-aos-duration="1500">
                            <div className="banner_slider">
                                <div className="left_icon">
                                    <img src={msg} alt="image" />
                                </div>
                                <div className="right_icon">
                                    <img src={shield} alt="image" />
                                </div>
                                <OwlCarousel id="frmae_slider" className="owl-carousel owl-theme owl-loaded owl-drag" {...frmae_slider}>
                                    <div className="item" >
                                        <div className="slider_img">
                                            <img src={screen2} alt="image"   style={{backgroundColor: '#F6F4FE'}}/>
                                        </div>
                                    </div>
                                    <div className="item" >
                                        <div className="slider_img">
                                            <img src={screen1} alt="image"   style={{backgroundColor: '#F6F4FE'}}/>
                                        </div>
                                    </div>
                                    <div className="item" >
                                        <div className="slider_img">
                                            <img src={screen} alt="image"   style={{backgroundColor: '#F6F4FE'}}/>
                                        </div>
                                    </div>
                                    {/*
                                    <div className="item">
                                        <div className="slider_img">
                                            <img src={screen} alt="image" />
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="slider_img">
                                            <img src={screen} alt="image" />
                                        </div>
                                    </div>
                                    */}
                                </OwlCarousel>
                                {/*
                                <div className="slider_frame">
                                    <img src={frame} alt="image" />
                                </div>
                                */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Sliderone
