import React from 'react'
import img1 from '../../../assets/images/design_client_1.png'
import img2 from '../../../assets/images/secure_data.png'
import img3 from '../../../assets/images/design_client_2.png'
import img4 from '../../../assets/images/modern03.png'
import Sectionbg from "../../../assets/images/section-bg.png"

const Main = ({gredient}) => {

  const designBlocks = [
    {
      title: 'Актуальний розклад занять',
      content: 'Завжди будьте в курсі змін та нових програм.'
    },
    {
      title: 'Підтримка в режимі реального часу',
      content: 'Миттєві відповіді на Ваші питання.'
    },
    {
      title: 'Персональні сповіщення',
      content: 'Нагадування про тренування та події, щоб ви ніколи не пропустили важливе заняття.'
    },
    {
      title: 'Оплата онлайн',
      content: 'Зручна система оплати за абонементи прямо через бот.'
    },
    {
      title: 'Консультації тренерів',
      content: 'Можливість ставити питання і отримувати поради від наших досвідчених інструкторів.'
    }
  ];

  return (
    <>
        <section className={`row_am modern_ui_section ${gredient && "gredient-bg"}`} >
            {gredient &&
            <div className="modernui_section_bg modernui-gredient"> <img src={Sectionbg} alt="image"/> </div>}
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="ui_text">
                        <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                            <h2>Telegram Bot для <span>Клієнтів</span></h2>
                            <p>
                            Хочете записатися на тренування, отримати персональні рекомендації чи дізнатися про новинки студії? Наш бот допоможе зробити це швидко та просто!
                            </p>
                        </div>
                        <ul className="design_block">
                          {designBlocks.map((block, index) => (
                            <li key={index} data-aos="fade-up" data-aos-duration="1500">
                              <h4>{block.title}</h4>
                              <p>{block.content}</p>
                            </li>
                          ))}
                        </ul>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="ui_images" data-aos="fade-in" data-aos-duration="1500">
                        <div className="left_img">
                            <img className="moving_position_animatin" src={img1} alt="image" />
                        </div>
                        <div className="right_img">
                            {[img2, img3].map((img, index) => (
                              <img key={index} className="moving_position_animatin" src={img} alt="image" />
                            ))}
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Main
