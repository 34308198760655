import React from 'react'
import img1 from '../../../assets/images/design_coach_1.png'
import img2 from '../../../assets/images/secure_data.png'
import img3 from '../../../assets/images/design_coach_2.png'
import Sectionbg from "../../../assets/images/section-bg.png"

const Main = ({gredient}) => {

  const designBlocks = [
    {
      title: 'Керування розкладом тренувань',
      content: 'Додавайте, редагуйте та оновлюйте свій розклад у реальному часі. Інтуїтивно зрозумілий інтерфейс дозволяє швидко налаштовувати заняття та повідомляти клієнтів про зміни.'
    },
    {
      title: 'Клієнтська база та відстеження відвідувань',
      content: 'Бот допомагає вести базу клієнтів, відзначати відвідування, перевіряти наявність місць на тренуваннях та контролювати заповненість груп.'
    },
    {
      title: 'Фінансовий облік',
      content: 'Відстежуйте оплати за заняття, залишок абонементів клієнтів та інші фінансові показники, щоб завжди бути в курсі стану розрахунків. Моментальний розрахунок заробітної плати.'
    },
    {
      title: 'Збір відгуків та аналітика',
      content: 'Збирайте відгуки від клієнтів безпосередньо через бота, щоб розуміти, що можна вдосконалити. Аналізуйте дані та приймайте рішення для покращення сервісу.'
    }
  ];

  return (
    <>
        <section className={`row_am modern_ui_section ${gredient && "gredient-bg"}`} >
            {gredient &&
            <div className="modernui_section_bg modernui-gredient"> <img src={Sectionbg} alt="image"/> </div>}
            <div className="container">
                <div className="row">

                    <div className="col-lg-6">
                        <div className="ui_images" data-aos="fade-in" data-aos-duration="1500">
                        <div className="left_img">
                            <img className="moving_position_animatin" src={img1} alt="image" />
                        </div>
                        <div className="right_img">
                            {[img2, img3].map((img, index) => (
                              <img key={index} className="moving_position_animatin" src={img} alt="image" />
                            ))}
                        </div>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="ui_text">
                        <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                            <h2>Telegram Bot для <span>Тренерів</span></h2>
                            <p>
                            Для тренера важливо мати під рукою інструменти, які дозволяють ефективно керувати розкладом занять,
                            взаємодіяти з клієнтами та контролювати процес тренувань.
                            Наш телеграм-бот створений спеціально для тренерів, щоб спростити управління всіма аспектами їхньої роботи, забезпечити швидкий доступ до інформації та підвищити продуктивність.
                            </p>
                        </div>
                        <ul className="design_block">
                          {designBlocks.map((block, index) => (
                            <li key={index} data-aos="fade-up" data-aos-duration="1500">
                              <h4>{block.title}</h4>
                              <p>{block.content}</p>
                            </li>
                          ))}
                        </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Main
