import React from 'react'

function Map() {
    return (
        <>
            <section className="row_am map_section">
                <div className="container">
                    <div className="map_inner">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d21160.95383499763!2d35.011552802876885!3d48.47342659943822!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40dbe2c2c60241bd%3A0x999c964048051b3e!2sMOST-City!5e0!3m2!1sen!2sua!4v1730660579062!5m2!1sen!2sua" width="100%" height="510" style={{ border: "0" }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Map
