import React , {useState} from 'react'
import { Link } from 'react-router-dom'
import img from '../../../assets/images/download_app.jpg'
import img1 from '../../../assets/images/create_account.jpg'
import img2 from '../../../assets/images/enjoy_app.jpg'
import line from '../../../assets/images/anim_line.png'
import banner from '../../../assets/images/banner-shape1.png'
import banner1 from '../../../assets/images/banner-shape2.png'
import banner2 from '../../../assets/images/banner-shape3.png'
import video from '../../../assets/images/play_icon.png'
import ytvideo from '../../../assets/images/yt_thumb.png'
import Lineimg from "../../../assets/images/anim_line.png"
import Shape1 from "../../../assets/images/banner-shape1.png"
import Shape2 from "../../../assets/images/banner-shape2.png"
import Shape3 from "../../../assets/images/banner-shape3.png"
import Thumbimg from "../../../assets/images/yt_thumb.png"


const Main = ({dark}) => {
    const [ytShow , setytShow] = useState (false)

  return (
    <>
        <section className="row_am how_it_works" id="how_it_work">
            <div className="container">
                <div className={`how_it_inner ${dark && "dark"}`} style={{paddingBottom: '50px'}}>
                    <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
                        <h2><span>Бажаєте отримати?</span> 3 простих кроки</h2>
                        <p>Ми пропонуємо зручний і швидкий процес початку співпраці. Ось три прості кроки, які допоможуть вам швидко перейти до роботи з нами:</p>
                    </div>
                    <div className="step_block">
                        <ul>
                            <li>
                                <div className="step_text" data-aos="fade-right" data-aos-duration="1500">
                                <h4>Запит та консультація</h4>
                                <p>Зв’яжіться з нами будь-яким зручним способом – через форму зворотного зв’язку на сайті, телефон або електронну пошту. Ми швидко відповімо на ваш запит і домовимося про консультацію, де з’ясуємо ваші потреби та деталі реалізації.</p>
                                </div>
                                <div className="step_number">
                                <h3>01</h3>
                                </div>
                                <div className="step_img" data-aos="fade-left" data-aos-duration="1500">
                                <img src={img} alt="image" />
                                </div>
                            </li>
                            <li>
                                <div className="step_text" data-aos="fade-left" data-aos-duration="1500">
                                <h4>Підготовка індивідуальної пропозиції</h4>
                                <p>На основі вашого запиту ми створюємо персоналізовану пропозицію. У ній буде враховано всі особливості вашого бізнесу, терміни виконання. Обговорюємо деталі, вносимо необхідні корективи та затверджуємо план співпраці.</p>
                                </div>
                                <div className="step_number">
                                <h3>02</h3>
                                </div>
                                <div className="step_img" data-aos="fade-right" data-aos-duration="1500">
                                <img src={img1} alt="image" />
                                </div>
                            </li>
                            <li>
                                <div className="step_text" data-aos="fade-right" data-aos-duration="1500">
                                <h4>Початок роботи та підтримка</h4>
                                <p>Після затвердження плану ми відразу ж починаємо виконання проєкту.
                                Ви зможете слідкувати за процесом, отримувати звіти та ставити питання.
                                Ми також надаємо підтримку після завершення проєкту, щоб забезпечити успішний результат та довгострокову співпрацю.</p>
                                </div>
                                <div className="step_number">
                                <h3>03</h3>
                                </div>
                                <div className="step_img" data-aos="fade-left" data-aos-duration="1500">
                                <img src={img2} alt="image" />
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>


            </div>
        </section>

    </>
  )
}

export default Main
