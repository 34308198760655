import React, { useState } from 'react'
import Sectionbg from "../../../assets/images/section-bg.png"

const Main = ({ gredient }) => {

    const [activeFaq, setActiveFaq] = useState({
        a: true,
        b: false,
        c: false,
        d: false,
    });

    const toggleFaq = (faq) => {
        setActiveFaq(prevState => {
            const newState = {};
            Object.keys(prevState).forEach(key => {
                newState[key] = key === faq ? !prevState[key] : false;
            });
            return newState;
        });
    };


    return (
        <>
            <section className="row_am faq_section">
                {gredient && <div className="faq_bg"> <img src={Sectionbg} alt="image" /> </div>}
                <div className="container">
                    <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
                        <h2><span>Поширені запитання</span> щодо сервісу</h2>
                        <p>Цей розділ допомагає користувачам знайти відповіді на найчастіші запити, пов'язані з функціоналом, цінами, реєстрацією та технічною підтримкою нашого сервісу.
                        Тут ви дізнаєтеся, як працює наш сервіс, які його переваги, як зареєструватися, та іншу важливу інформацію.</p>
                    </div>
                    <div className="faq_panel">
                        <div className="accordion" id="accordionExample">
                            <div className="card" data-aos="fade-up">
                                <div className="card-header" id="headingOne">
                                    <h2 className="mb-0">
                                        <button type="button" className={`btn btn-link ${activeFaq.a ? "" : "collapsed"}`} onClick={() => toggleFaq('a')} data-toggle="collapse" data-target="#collapseOne">
                                            {activeFaq.a ? <i className="icon_faq icofont-minus"></i> : <i className="icon_faq icofont-plus"></i>}
                                            1. Як працює наш сервіс?
                                        </button>
                                    </h2>
                                </div>
                                <div id="collapseOne" className={`collapse ${activeFaq.a ? "show" : ""}`} aria-labelledby="headingOne" data-parent="#accordionExample">
                                    <div className="card-body">
                                        <p>
Наш сервіс Arena.sport дозволяє автоматизуватись всі організаційні процеси в спортивних студіях.
Ваші клієнти зможуть записуватись на заняття, спілкуватиісь як з тренерами так і адміністраторами,
купувати абонементи будь-який зручним способом та багато-багато інших корисних функцій.
Arena.sport - ідеальний як для невеличких студій так і для компаній що мають 1000+ клієнтів.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="card" data-aos="fade-up">
                                <div className="card-header" id="headingTwo">
                                    <h2 className="mb-0">
                                        <button type="button" className={`btn btn-link ${activeFaq.b ? "" : "collapsed"}`} onClick={() => toggleFaq('b')} data-toggle="collapse" data-target="#collapseTwo">
                                            {activeFaq.b ? <i className="icon_faq icofont-minus"></i> : <i className="icon_faq icofont-plus"></i>}
2. Чи потрібно створювати обліковий запис для використання сервісу?
                                        </button>
                                    </h2>
                                </div>
                                <div id="collapseTwo" className={`collapse ${activeFaq.b ? "show" : ""}`} aria-labelledby="headingTwo" data-parent="#accordionExample">
                                    <div className="card-body">
                                        <p>
Так, щоб скористатися всіма функціями сервісу, необхідно створити обліковий запис.
Для цього надайте, будь-ласка, інформацію про вашу компанію: instagram, facebook, сайт та іншу інформацію, що описує як зараз проходять процеси в компанії.
                                      </p>
                                    </div>
                                </div>
                            </div>
                            <div className="card" data-aos="fade-up">
                                <div className="card-header" id="headingThree">
                                    <h2 className="mb-0">
                                        <button type="button" className={`btn btn-link  ${activeFaq.c ? "" : "collapsed"}`} onClick={() => toggleFaq('c')} data-toggle="collapse" data-target="#collapseThree">
                                            {activeFaq.c ? <i className="icon_faq icofont-minus"></i> : <i className="icon_faq icofont-plus"></i>}
3. Які є переваги використання нашого сервісу?
                                            </button>
                                    </h2>
                                </div>
                                <div id="collapseThree" className={`collapse ${activeFaq.c ? "show" : ""}`} aria-labelledby="headingThree" data-parent="#accordionExample">
                                    <div className="card-body">
                                        <p>
Ви отримуєте персональний Telegram Bot саме вашої компанії: унікальне імʼя, логотип, посилання. Також є можливість додання функцій, яких не вистачає сам вам.
Сервіс на 100% підлаштовується під потреби кожної студії.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="card" data-aos="fade-up" >
                                <div className="card-header" id="headingFour">
                                    <h2 className="mb-0">
                                        <button type="button" className={`btn btn-link  ${activeFaq.d ? "" : "collapsed"}`} onClick={() => toggleFaq('d')} data-toggle="collapse" data-target="#collapseFour">
                                            {activeFaq.d ? <i className="icon_faq icofont-minus"></i> : <i className="icon_faq icofont-plus"></i>}
                                            5. Як отримати допомогу, якщо виникли проблеми?
                                        </button>
                                    </h2>
                                </div>

                                <div id="collapseFour" className={`collapse ${activeFaq.d ? "show" : ""}`} aria-labelledby="headingFour" data-parent="#accordionExample">
                                    <div className="card-body">
                                        <p>
Якщо у вас виникли питання чи проблеми, ви можете звернутися до нашої служби підтримки через "електронну пошту", "гарячу лінію", "онлайн-чат".
Ми завжди готові допомогти та відповісти на ваші запитання.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Main
