import OwlCarousel from 'react-owl-carousel'
import { Link } from 'react-router-dom'
import React from 'react'
import all from '../../../assets/images/avatar_testimonial.png'

import user1 from '../../../assets/images/testimonial_user1.png'
import user2 from '../../../assets/images/testimonial_user2.png'
import user3 from '../../../assets/images/testimonial_user3.png'
import user4 from '../../../assets/images/testimonial_user4.png'
import user5 from '../../../assets/images/testimonial_user5.png'

const Main = () => {

    const testimonial_slider = {
        loop: true,
        margin: 10,
        nav: false,
        autoplay: true,
        smartSpeed: 1500,
        dots: true,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 1
            }
        }
    }
    return (
        <>
            <section className="row_am testimonial_section">
                <div className="container">
                    <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
                        <h2>Що про нас <span>розповідають клієнти</span></h2>
                        <p>Ми пишаємося тим, що наші клієнти залишаються задоволеними після співпраці з нами. Їхня довіра – найкращий показник нашої роботи. Ось декілька відгуків, які підкреслюють високий рівень обслуговування та професіоналізму нашої команди:</p>
                    </div>
                    <div className="testimonial_block" data-aos="fade-in" data-aos-duration="1500">
                        <OwlCarousel id="testimonial_slider" {...testimonial_slider} className="owl-carousel owl-theme owl-loaded owl-drag">
                            <div className="item">
                                <div className="testimonial_slide_box">
                                    <p className="review">
                                        “Телеграм-бот став справжнім відкриттям для нашої студії! Завдяки автоматизованій системі запису та нагадуванням, ми значно зменшили кількість пропущених занять. А головне – наші клієнти дуже задоволені, що можуть записуватись за пару кліків.”
                                    </p>
                                    <div className="testimonial_img">
                                        <img src={user1} alt="image" />
                                    </div>
                                    <h3>Олена К.</h3>
                                    <span className="designation">власниця студії танців</span>
                                </div>
                            </div>
                            <div className="item">
                                <div className="testimonial_slide_box">
                                    <p className="review">
                                        “Використання бота суттєво полегшило мою роботу. Замість того, щоб витрачати час на ведення журналів записів та телефонні дзвінки, я можу зосередитись на обслуговуванні клієнтів. Зручний, простий і надзвичайно корисний!”
                                    </p>
                                    <div className="testimonial_img">
                                        <img src={user2} alt="image" />
                                    </div>
                                    <h3>Ігор М.</h3>
                                    <span className="designation">адміністратор фітнес-центру</span>
                                </div>
                            </div>
                            <div className="item">
                                <div className="testimonial_slide_box">
                                    <p className="review">
                                        “Мені дуже подобається, що бот дозволяє швидко отримувати зворотний зв'язок від клієнтів та навіть запускати опитування про вподобання та зручний час занять. Це значно покращує якість нашої роботи і допомагає враховувати побажання відвідувачів.”
                                    </p>
                                    <div className="testimonial_img">
                                        <img src={user3} alt="image" />
                                    </div>
                                    <h3>Світлана П.</h3>
                                    <span className="designation">тренер йоги</span>
                                </div>
                            </div>
                            <div className="item">
                                <div className="testimonial_slide_box">
                                    <p className="review">
                                        “Наша команда вже не уявляє, як працювати без цього телеграм-бота. Він автоматизує розсилку важливих повідомлень, знижує навантаження на адміністрацію та дозволяє клієнтам швидко отримувати актуальну інформацію. Рекомендую!”
                                    </p>
                                    <div className="testimonial_img">
                                        <img src={user4} alt="image" />
                                    </div>
                                    <h3>Андрій Л.</h3>
                                    <span className="designation">керівник студії бойових мистецтв</span>
                                </div>
                            </div>
                            <div className="item">
                                <div className="testimonial_slide_box">
                                    <p className="review">
                                        “Ми тестували різні інструменти для комунікації з клієнтами, але телеграм-бот виявився найзручнішим. Інтуїтивний інтерфейс, можливість створювати нагадування та вести запис на тренування – просто незамінні функції.”
                                    </p>
                                    <div className="testimonial_img">
                                        <img src={user5} alt="image" />
                                    </div>
                                    <h3>Марина С.</h3>
                                    <span className="designation">менеджер з комунікацій у студії</span>
                                </div>
                            </div>
                        </OwlCarousel>

                        <div className="avtar_faces">
                            <img src={all} alt="image" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Main
