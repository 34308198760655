import OwlCarousel from 'react-owl-carousel'
import React from 'react'
import img1 from '../../../assets/images/screen-1.png'
import img2 from '../../../assets/images/screen-2.png'
import img3 from '../../../assets/images/screen-3.png'
import img4 from '../../../assets/images/screen-4.png'
import img5 from '../../../assets/images/screen-5.png'
import img6 from '../../../assets/images/screen-6.png'
import img7 from '../../../assets/images/screen-7.png'
import img8 from '../../../assets/images/screen-8.png'
import img9 from '../../../assets/images/screen-9.png'

const Main = () => {

  const screen_slider = {
    loop: true,
    margin: 10,
    nav: false,
    autoplay: true,
    smartSpeed: 1500,
    center: true,
    dots: true,
    responsive: {
      0: {
        items: 2
      },
      600: {
        items: 3
      },
      1000: {
        items: 5
      }
    }
  }

  const images = [img1, img2, img3, img4, img5, img6, img7, img8, img9];

  return (
    <>
      <section className="row_am interface_section">
        <div className="container-fluid">
          <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
            <h2>Інноваційна <span>розробка</span></h2>
            <p>
            Використання нашого Telegram-бота спрощує  управління бізнес-процесами, забезпечуючи зручність та ефективність використання.
            <br />
            Підключіться до Arena.sport і відкрийте для себе всі переваги сучасного підходу до автоматизації та управління інформацією!

            </p>
          </div>
          <div className="screen_slider">
            <OwlCarousel id="screen_slider" {...screen_slider} className="owl-carousel owl-theme owl-loaded owl-drag">
              {images.map((image, index) => (
                <div className="item" key={index}>
                  <div className="">
                    <img src={image} alt="image" />
                  </div>
                </div>
              ))}
            </OwlCarousel>
          </div>
        </div>
      </section>
    </>
  )
}

export default Main
