import React from 'react'
import img1 from '../../../assets/images/design_business_1.png'
import img2 from '../../../assets/images/secure_data.png'
import img3 from '../../../assets/images/design_business_2.png'
import img4 from '../../../assets/images/design_business_3.png'
import Sectionbg from "../../../assets/images/section-bg.png"

const Main = ({gredient}) => {

  const designBlocks = [
    {
      title: 'Управління розкладом занять',
      content: 'Оновлюйте, змінюйте та публікуйте розклад занять у реальному часі, щоб ваші клієнти завжди були в курсі актуальних подій.'
    },
    {
      title: 'Сповіщення та нагадування',
      content: 'Надсилайте автоматичні сповіщення про зміни в розкладі, спеціальні пропозиції або важливі оголошення.'
    },
    {
      title: 'Зворотний звʼязок',
      content: 'Збирайте відгуки та пропозиції від клієнтів, що допоможе вдосконалити ваші послуги.'
    },
    {
      title: 'Фінансовий контроль',
      content: 'Відстежуйте платежі, залишок абонементів та нагадуйте клієнтам про необхідність продовження послуг.'
    }
  ];

  return (
    <>
        <section className={`row_am modern_ui_section ${gredient && "gredient-bg"}`} >
            {gredient &&
            <div className="modernui_section_bg modernui-gredient"> <img src={Sectionbg} alt="image"/> </div>}
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="ui_text">
                        <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                            <h2>Telegram Bot для <span>Адміністратора</span></h2>
                            <p>
                            Сучасні технології значно спрощують процес управління бізнесом, і одним із найефективніших інструментів є телеграм-бот для адміністраторів.
                            Якщо ви керуєте спортивною студією, фітнес-центром - цей бот стане незамінним помічником у повсякденних завданнях.
                            Він дозволяє швидко і просто управляти всією інформацією про студію, підтримувати контакт з клієнтами, тренерами та оптимізувати роботу.
                            </p>
                        </div>
                        <ul className="design_block">
                          {designBlocks.map((block, index) => (
                            <li key={index} data-aos="fade-up" data-aos-duration="1500">
                              <h4>{block.title}</h4>
                              <p>{block.content}</p>
                            </li>
                          ))}
                        </ul>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="ui_images" data-aos="fade-in" data-aos-duration="1500">
                        <div className="left_img">
                            <img className="moving_position_animatin" src={img1} alt="image" />
                        </div>
                        <div className="right_img">
                            {[img2, img3].map((img, index) => (
                              <img key={index} className="moving_position_animatin" src={img} alt="Автоматизація спортивної студії." />
                            ))}
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Main
